<!-- <svelte:options accessors={true} /> -->

<script>
import { onMount, onDestroy } from 'svelte';  
import { play } from './stores/player.js';
import { current } from './stores/track.js';

import { tracks, clear } from './stores/playlist.js';
import { modal } from './stores/modal.js';

let element;

// on mount, create bootstrap modal and store it
onMount(() => {
  modal.set( new bootstrap.Modal( element ) );
})


// on destroy, dispose modal
onDestroy(() => {
  $modal.dispose();
})
</script>

<style>
.modal-content { border-radius: 0; }
.modal-header .modal-title { color: #DF4F1F; }
.modal-body .list-group { border-radius: 0; }
.modal-body .list-group .list-group-item:first-child { border-top: 0; }
.modal-body .list-group .list-group-item:last-child { border-bottom: 0; }
.modal-body .list-group .song-name a { text-decoration: none; }
.modal-body .list-group .song-name a:hover { text-decoration: underline; }
</style>


<!-- modal with playlist -->
<div class="modal show" bind:this={element}>
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
    <div class="modal-content text-dark shadow">
      <div class="modal-header">
        <h5 class="modal-title">{ window.vocab.player.history.title }</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>
      {#if $tracks.length }
        <div class="modal-body p-0">

          <ul class="list-group list-group-flush">
            {#each $tracks as track }
              <li class="list-group-item list-group-item-action { ( $current && $current.src == track.src ) ? 'bg-light' : '' }">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <div class="song-name"><a href="{ track.href }" class="link-dark fw-600">{ track.title }</a></div>
                    <div class="song-singer text-muted small">{ track.singer }</div>
                  </div>
                  <div>
                    <a href="javascript:" class="link-primary song-play" data-track="{track.id}" on:click={() => play(track)}>
                      <i class="fa-solid fa-2x fa-circle-play"></i>
                    </a>
                  </div>
                </div>
              </li>
            {/each}
          </ul>
        </div>
      {:else}
        <div class="modal-body">
          <div class="text-center p-3 text-muted">{ window.vocab.player.history.empty }</div>
        </div>
      {/if}
      <div class="modal-footer justify-content-center">
        <a href="#" class="btn btn-sm btn-outline-secondary" on:click={clear}><i class="fa-regular fa-fw fa-trash"></i> Effacer l'historique</a>
      </div>
    </div>
  </div>
</div>
