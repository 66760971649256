<script>
	import Fa from './Fa.svelte';
	
	import { id, favorite } from '../stores/track.js';

	// import on mount, destroy
	import { onMount, onDestroy } from 'svelte';


	// disabled if not current track selected
	$: title = window.vocab.player.button.favorite[ $favorite ? 1 : 0 ];
	$: style = $id ? ( $favorite ? 'duotone' : 'solide' ) : 'light';
	$: disabled = $id ? '' : 'disabled';


	// toolitip 
	let btn, tip;

	// onmount init tooltip with bootstrap 5
	onMount(() => {
		tip = new bootstrap.Tooltip(btn, {
		  title: title,
		});
	})

	// on destroy, dispose tooltip
	onDestroy(() => {
		tip.dispose();
	})

	// change title on favorite change
	onDestroy( favorite.subscribe(function( value ) {
		
		if( tip ) {
			tip.setContent( { '.tooltip-inner': window.vocab.player.button.favorite[ value ? 1 : 0 ] } );
		}
	}) );


	// add to playlist function
	function onclick() {
		if( !$id ) return;

		// start Nprogress
		NProgress.start();

		jQuery.post( window.vocab.url.favorite.replace(/\d+$/, $id), function( result ) {
			
			// update favorite (triggger event to change favorites on page)
			jQuery(document).trigger('favorite',result.data);

		}).always(function() {
			
			// stop Nprogress, reload flash messages
			NProgress.done();
			jQuery(document).trigger('retoast');
		});

	}


</script>

<style>
	button { font-size: 1.5em; }
</style>

<button {disabled} class="btn btn-link link-light" on:click={onclick} data-bs-toggle="tooltip" bind:this={btn}>
	<Fa type={style} icon="bookmark fw" />
</button>