<script>
	import { play } from '../stores/player.js';
	import { prev } from '../stores/playlist.js';
	import Fa from './Fa.svelte';

	$: disabled = $prev ? '' : 'disabled';
		
	let onclick = function(e) {
		e.preventDefault();
		if( !$prev ) return;
		play( $prev, true );
	}

</script>

<button on:click={onclick} {disabled} class="btn btn-link link-light px-1"><Fa icon="backward-step fw"/></button>
