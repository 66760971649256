import { get, writable } from 'svelte/store';


/**
 * Playlist modal management
 */
export const modal = writable( null );

// opened state
export const opened = writable(false);

// open function => changes state to true
export const open = function() {
  if( get( modal ) ) {
    get( modal ).show();
  }
}

// close function => changes state to false
export const close = function() {
  if( get(modal) ) {
    get(modal).hide();
  }
}

// toggle function => changes state to opposite
export const toggle = function() {
  if( get(modal) ) {
    get(modal).toggle();
  }
}