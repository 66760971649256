<script>
	import { volume, setVolume } from '../stores/audio.js';
	import Fa from './Fa.svelte';

	let show = 'd-none';

	//  choose icon based on volume level
	const icons = [ 'volume-xmark', 'volume-off', 'volume-low', 'volume', 'volume-high' ];	
	$: icon = $volume ? icons[ Math.ceil( $volume * (icons.length-1) ) ] : icons[0];


	/**
	 * popover controls
	 */
	const toggle = function(e) {
		// e.preventDefault();
		show = ( show == 'show' ) ? 'd-none' : 'show';
	}

	const open = function(e) {
		// e.preventDefault();
		show = 'show';
	}

	const close = function(e) {
		// e.preventDefault();
		show = 'd-none';
	}

	

	/**
	 * change volume events
	 */
	const onwheel = function(e) {
		open();
		e.preventDefault();

		var dir = ( ( e.deltaY > 0 ) ? -1 : 1 );
		setVolume( $volume + dir * 0.1 )
	}

	const onmouse = function(e) {
		e.preventDefault();

		if( this.offsetWidth ) {
			setVolume( e.offsetX / this.offsetWidth );
		}
	}

	const onmousedown = function(e) {
		this.addEventListener( 'mousemove', onmouse );
    }

	const stopmousemove = function(e) {
		this.removeEventListener( 'mousemove', onmouse );
	}
</script>


<style>
	.popover {
		position: absolute; 
		inset: 0px auto auto 0px; 
		margin: 0px; 
		transform: rotate(-90deg) translate(165px, -115px);; 
	}
	.popover-arrow {
		position: absolute; 
		top: 0px; 
		transform: translate(0px, 16px);
	}
	.progress {
		cursor: pointer;
		width: 20em;
	}

	.progress-bar { transition:none }
</style>

<div on:mouseleave={close} on:wheel={onwheel} style="position: relative;">
	<button class="btn btn-link link-light" on:click={toggle}>
    	<Fa icon="{icon} fw"/>
  	</button>

	<div class="popover bs-popover-auto fade {show}" data-popper-placement="right">
		<div class="popover-arrow"></div>
		<div class="popover-body">
			<div class="progress" on:click={onmouse} on:mousedown={onmousedown} on:mouseleave={stopmousemove} on:mouseup={stopmousemove}>
				<div class="progress-bar bg-dark" role="progressbar" style="width: { $volume * 100 }%;"></div>
			</div>
		</div>
	</div>
</div>