
import Player from './Player/Player.svelte';
import Playlist from './Player/Playlist.svelte';

import { play, playRandom, random } from './Player/stores/player.js';
import { tracks, next } from './Player/stores/playlist.js';
import { audio, paused } from './Player/stores/audio.js';
import { current } from './Player/stores/track.js';
import { get } from 'svelte/store';
// import { onMount, onDestroy } from 'svelte';

// create global player instance
const player = new Player({
  target: document.getElementById('player'),
})

const playlist = new Playlist({
  target: document.getElementById('playlist'),
})


// Expose some API
player.play = play;
// player.pause = pause;

// make it global
window.player = player;
window.playlist = playlist;
window.audio = audio;

// toggle play/pause on all click btn on page
const toggleIcon = function( track ) {
  $(document).find('.song-play>i').removeClass('fa-circle-pause').addClass('fa-circle-play');

  if( track ) {
    $(document).find('.song-play[data-track='+track.id+']>i').addClass('fa-circle-pause').removeClass('fa-circle-play');
  }
}



//subscribe to playing track
current.subscribe( function( track ) {
  toggleIcon( track );

  document.dispatchEvent( new CustomEvent('track',{detail:track}) );
});

//subscribe to paused
paused.subscribe( function( paused ) {
  toggleIcon( paused ? null : get( current ) );
});

// listen to random state
random.subscribe( function( random ) {
  // if random is enabled and player is paused, play random track
  if( random && get( paused ) ) {
    playRandom();
  }
});

// listen to audio ended event
audio.addEventListener('ended', function() {

  // if random is enabled, play random track
  if( get( random ) ) {
    playRandom();
  }

  // else play next track
  else {
    play( get( next ) );
  }
});

// dispatch custom audio events to document, with current trak as detail
audio.addEventListener( 'playing', function() {
  document.dispatchEvent( new CustomEvent( 'playing', {detail: get( current )}));
});

audio.addEventListener( 'pause', function() {
  document.dispatchEvent( new CustomEvent( 'paused', {detail: get( current )}));
});

audio.addEventListener( 'ended', function() {
  document.dispatchEvent( new CustomEvent( 'ended', {detail: get( current )}));
});


// listen to 'navigated' event on document, and toggle play/pause icons
document.addEventListener('navigated', function() {
  console.log('navigated');
  toggleIcon( get( current ) );
});



// export player
// export default window.player;
