<script>
  import Fa from './Fa.svelte';

  import { length } from '../stores/playlist.js';
  import { toggle } from '../stores/modal.js';

  // import on mount, destroy
  import { onMount, onDestroy } from 'svelte';

  // our button
  let btn, tip;

  // onmount init tooltip with bootstrap 5
  onMount(() => {
    tip = new bootstrap.Tooltip(btn, {
      title: window.vocab.player.button.playlist,
    });
  })

  // on destroy, dispose tooltip
  onDestroy(() => {
    tip.dispose();
  })


  // disable button if playlist is empty
  $: disabled = $length ? '' : 'disabled';
</script>

<style>
  button { font-size: 1.5em; }
</style>

<!-- <div class="dropup btn-group" style="font-size:1.5em"> -->
<button type="button" {disabled} class="btn btn-link link-light" on:click={() => toggle()} bind:this={btn}>
  <Fa icon="history fw"/>
</button>
