
// https://developer.mozilla.org/fr/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#d%C3%A9tection_de_la_fonction_localstorage
function storageAvailable(type) {
  try {
    var storage = window[type],
      x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage.length !== 0
    );
  }
}

// storage abstraction 
export const storage = function( type ) {

	const store = type+'Storage';

	// const available = storageAvailable( store );
	
	return {
		getItem: function( key, def ) {
			try {
				let val = JSON.parse( window[store].getItem( key ) );
				return ( val !== null ) ? val : def;
			}
			catch( e ) {
				return def;
			}
		},
		hasItem: function( key )  {
			try {
				return window[store].getItem( key ) !== null;
			}
			catch(e) {
				return false;
			}
		},
		setItem: function( key, val ) {
			try {				
				window[store].setItem( key, JSON.stringify(val) );
				return true;
			}
			catch( e ) {
				return false;
			}
		}
	}
}


export default storage;