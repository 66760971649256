<script>
	import { onDestroy } from 'svelte';

	import { duration, time, seek } from '../stores/audio.js';


	// set progress bar width
	$: width = ( ($time && $duration ) ? ( $time / $duration ) : 0 ) * 100;

	// display time
	let minutes = '00', seconds = '00';
	$: {
		seconds = Math.floor( $time % 60 );
		minutes = Math.floor( $time / 60 );

		if( minutes < 10 ) minutes = "0"+minutes;
		if( seconds < 10 ) seconds = "0"+seconds;
	}


	// on mouse event seek audio to it
	let onmouse = function( e ) {

		e.preventDefault();

		if( $duration && this.offsetWidth ) {
			seek( e.offsetX / this.offsetWidth );
		}
		
	}

	const onmousedown = function(e) {
		this.addEventListener( 'mousemove', onmouse );
  }

	const stopmousemove = function(e) {
		this.removeEventListener( 'mousemove', onmouse );
	}

/*
	let onwheel = function( e ) {
		e.preventDefault();
		if( !audio.duration ) return;

		var progress = audio.currentTime / audio.duration * 100;
		if( e.deltaY > 0 ){
			progress -= 10;
			if( progress < 0 ) progress = 0;
		}else{
			progress += 10;
			if( progress > 100 ) progress = 100;
		}
		audio.currentTime = audio.duration * progress / 100;
	}
*/
</script>

<div class="row align-items-center gx-2">
  <div class="col d-none d-md-block">
    <div class="progress" style="height: 1em;" on:click={onmouse} on:mousedown={onmousedown} on:mouseleave={stopmousemove} on:mouseup={stopmousemove}>
      <div class="progress-bar bg-dark-subtle" role="progressbar" style="width: {width}%; transition: none;"></div>
    </div>
  </div>
  <div class="col-auto" style="font-size:0.75em;"><span>{minutes}:{seconds}</span></div>
</div>
