import { readable } from 'svelte/store';
import { storage } from './storage';

// create audio player
export const audio = new Audio;


let createStorage = function( key, events ) {
	return readable( audio[key], function( set ) {
		
		let listen = function() {
			set( audio[key] );
		}

		for( let event of events ) {
			audio.addEventListener( event, listen );
		}

		return function() {
			for( let event of events ) {
				audio.removeEventListener( event, listen );
			}
		}
	});
}
// audio.addEventListener( 'playing', console.log );

// audio player states
export const ready = createStorage( 'readyState', ['canplay'] );
export const muted = createStorage( 'muted', ['volumechange'] );
export const volume = createStorage( 'volume', ['volumechange'] );
export const duration = createStorage( 'duration', ['durationchange'] );
export const time = createStorage( 'currentTime', ['timeupdate'] );
export const paused = createStorage( 'paused', [ 'pause', 'play' ] );


// load volume from session storage
let volumeRatio = storage('session').getItem('player-volume', 1); 
audio.volume = volumeRatio;

// on volume change, store it in session storage
volume.subscribe( function( volume ) {
	storage('session').setItem( 'player-volume', volume );
});



const bound = function( ratio ) {
	return Math.min( Math.max( 0, ratio ), 1 );
}

const normalize = function( ratio ) {
	return bound( Math.round( ratio * 1000 ) / 1000 );
}

// set volume (in %)
export const setVolume = function( ratio ) {
	audio.volume = normalize( ratio );
}

// set time (in % of duration)
export const seek = function( ratio ) {
	audio.currentTime = audio.duration ? audio.duration * bound( ratio ) : 0;
}


// // toggle mute, unmute
// export const mute = function() {
// 	audio.
// }