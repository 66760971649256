<script>
	import Fa from './Fa.svelte';
 	import { random } from '../stores/player.js'
 	
 	import { onMount, onDestroy } from 'svelte';

  // our button
  let btn, tip;

  // onmount init tooltip with bootstrap 5
  onMount(() => {
    tip = new bootstrap.Tooltip(btn, {
      title: window.vocab.player.button.random,
    });
  })

  // on destroy, dispose tooltip
  onDestroy(() => {
    tip.dispose();
  })

	// toggle random
	const toggleRandom = () => random.update( r => !r );
</script>

<button bind:this={btn} title="Lecture continue et aléatoire" class="btn btn-link link-light" on:click={toggleRandom}>
	<span class="fa-stack">
	    <i class="fa-{ $random ? 'solid' : 'light' } fa-circle fa-stack-2x"></i>
	    <i class="fa-solid fa-random fa-stack-1x text-{ $random ? 'secondary' : 'white' }"></i>
	 </span>	
	<!-- <Fa type={ $random ? 's' : 'l' } icon="random fw"/> -->
</button>