<script>
	import { play, random, playRandom } from '../stores/player.js';
	import { next } from '../stores/playlist.js';
	import Fa from './Fa.svelte';

	$: disabled = ( $next || $random ) ? '' : 'disabled';
		
	let onclick = function(e) {
		e.preventDefault();
		if( $random ) {
			playRandom();
			return;
		}
		
		if( $next ) {
			play( $next, true );
		}
	}
</script>

<button on:click={onclick} {disabled} class="btn btn-link link-light px-1"><Fa icon="forward-step fw"/></button>
