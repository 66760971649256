import { get, writable, derived } from 'svelte/store';
import { storage } from './storage';

// load current track from session storage
let track = storage('session').getItem('player-track', null ); 

export const current = writable( track );

const createStore = function( key ) {
	return derived( current, function( track ) {
		return track ? track[key] : null;
	});
}

// track id, src, singer, title, href
export const id = createStore( 'id' );
export const src = createStore( 'src' );
export const singer = createStore( 'singer' );
export const title = createStore( 'title' );
export const href = createStore( 'href' );

export const favorite = writable( false );


// on current track  change
current.subscribe( function( track ) {
	
	// store it in session storage
	storage('session').setItem( 'player-track', track );


	// request window.vocab.url.favorite with jQuery to update favorite store
	if( track ) {
		let url = window.vocab.url.favorite.replace(/\d+$/, track.id);

		jQuery.getJSON( url, function( result ) {
			favorite.set( result.data.favorite );
		});
	}
	else {
		favorite.set(false);
	}

});

jQuery(document).on('favorite',function(evt,data){
	
	if( get(id) == data.id ) {
		favorite.set( data.favorite );
	}
});

// export is, check if current is the same as given track
export const isCurrent = function( t ) {
	return t && t.src == get(src);
}