<script>
    import { current } from '../stores/track.js';
    import { next } from '../stores/playlist.js';

    $: track = $current || $next || { href: 'javascript:', title: '', singer: '' };
</script>

<p class="lh-sm mb-0" style="font-size:0.75em">    
    <a href={track.href} class="link-light">
        <strong>{ track.singer || '' }</strong><br/>
        <span class="fw-light">{ track.title || '' }</span>
    </a>
</p>