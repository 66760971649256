import { get, writable, readable } from 'svelte/store';
import { audio, paused	 } from './audio';
import { add } from './playlist';
import { current, src } from './track';
import { throttle } from 'lodash';



// on current track loaded in player
current.subscribe( function( track ) {

	// update audio src
	audio.src = track ? track.src : '';
	audio.track = track;
	audio.currentTime = 0;
	audio.load();

	// add track to play history
	add( track );
});


// is playing ?
export const playing = writable( false );

	// paused.subscribe( function( value ) {
	// console.log('paused', value);
	// playing.set( !value );
// });



// player is in random mode
export const random = writable( false );

// play track, or pause if already playing
export const play = throttle( function( track ) {

	// check validity [MELCHIOR-C](https://go-on-web.sentry.io/issues/2492913/)
	if( !audio || !track || !track.id ) {
		return;
	}
// console.log('play', track, audio.track);

	// define a new track ?
	if( !audio.track || audio.track.id != track.id ) {
		current.set( track );

		// play it
		audio.play();
		playing.set(true);
	}

	// if paused or force playing
	else if( !get( playing ) ) {
		audio.play();
		playing.set(true);
	}

	// otherwise, pause
	else {
		audio.pause();
		playing.set(false);
	}

	// just toggle pause or force playing
	// pause( force );
}, 500, {trailing: false});


export const playRandom = function() {
  // fetch track from window.vocab.url.random and play it
  fetch( window.vocab.url.random )
    .then( function( response ) {
      return response.json();
    })
    .then( function( track ) {
      play( track );
    });
}


// // toggle pause state
// export const pause = function( force ) {
	
// 	// was not playing or force => start playing
// 	if( !get( playing ) || force ) {
// 		audio.play().then( function() {
// 			playing.set(true);
// 		});
// 	}

// 	// else => pause
// 	else {
// 		audio.pause();
// 		playing.set(false);
// 	}
// }


// stop track
export const stop = function() {

	// stop audio playing
	audio.pause();

	// set current track to null
	current.set( null );
}




// // update time while playing
// audio.addEventListener("timeupdate",function() { 
// 	time.set( audio.currentTime );
// });

// // update volume on volume change
// audio.addEventListener('volumechange', function() {
// 	volume.set( audio.volume );
// 	muted.set( audio.muted );
// });

// audio.addEventListener("canplay",function(){
// 	ready.set(true);

// 	if( !get(paused) ){
// 		audio.play();
// 	}
// });

// audio.addEventListener('emptied',function() {
// 	ready.set(false);
// });

// audio.addEventListener('pause',function() {
// 	paused.set(true);
// });


// // when audio playing ended
// audio.addEventListener("ended",function() {

// 	// play next if exists in playlist
// 	let track = next( get(playing) );
// 	if( track ) {
// 		play( track, true );
// 	}
// 	else {
// 		stop();
// 	}
// });
