<!-- <svelte:options accessors={true} /> -->

<script>
  // import { play, pause, stop } from './stores/player.js';
  import { current } from './stores/track.js';
  import { length } from './stores/playlist.js';

  import Play from './lib/Play.svelte';
  import Next from './lib/Next.svelte';
  import Prev from './lib/Prev.svelte';

  import Timeline from './lib/Timeline.svelte';
  import Volume from './lib/Volume.svelte';
  import Track from './lib/Track.svelte';
  
  import Random from './lib/Random.svelte';
  import Favorite from './lib/Favorite.svelte';
  import History from './lib/History.svelte';

  // stop playing before leaving or updating page
  import { onDestroy } from 'svelte';
  onDestroy( stop );
  let show = ( $current || $length ) ? '' : '';//d-none';
</script>

<div class="text-light bg-secondary {show}">
  <div class="container">
    <div class="row align-items-center py-2 gx-2 gx-md-3">
      
      <!-- player controles (prev, play, next) -->
      <div class="col-auto">
        <div class="row align-items-center gx-2">
          <div class="col-auto d-none d-md-block" style="font-size:1.5em">
            <Prev/>
          </div>
          <div class="col-auto">
            <Play/>
          </div>
          <div class="col-auto d-none d-md-block" style="font-size:1.5em">
            <Next/>
          </div>
        </div>
      </div>

      <!-- volume control -->
      <div class="col-auto d-none d-md-block">
        <Volume/>
      </div>

      <!-- Current track info -->
      <div class="col col-md-auto text-small">
        <Track/>
      </div>

      <!-- playing indicator -->
      <div class="col d-none d-md-block">
        <Timeline/>
      </div>

      <!-- add current track to playlist button -->
      <div class="col-auto d-none d-md-block">
        <Favorite/>
      </div>

      <div class="col-auto"> 
        <Random/> 
      </div>

      <!-- open playlist button -->
      <div class="col-auto">
        <History/>
      </div>
    </div>
  </div>
</div>