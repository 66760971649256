// playlist.js
import { get, writable, derived } from 'svelte/store';
import { current } from './track';
import { storage } from './storage';


// index in play list
// export const index = writable(0); 


// load track list from session storage
let list = storage('local').getItem('player-playlist', []); 

// filter list to keep only valid tracks (with a src, title, id, href)
list = list.filter( function( track ) { return track && track.src && track.title && track.id && track.href } );

export const tracks = writable(list);

export const length = derived(tracks, $tracks => $tracks.length);
export const index = derived([tracks,current], ([$tracks,$current]) => {
  return $tracks.findIndex( t => ( $current && $current.src == t.src  ) );
});
export const first = derived(tracks, $tracks => $tracks[0]);
// export const last = derived(tracks, $tracks => $tracks[$tracks.length-1]);

// next track
export const next = derived( [tracks, index], ([$tracks, $index]) => {
  
  if( $index < 0 ) return null;
  
  $index++;
  if( $index >= $tracks.length ) return null;
  
  return $tracks[$index];
});

// prev track
export const prev = derived( [tracks, index], ([$tracks, $index]) => {
  
  if( $index < 0 ) return null;
  
  $index--;
  if( $index >= $tracks.length ) return null;
  
  return $tracks[$index];
});



// on track list change, store it in session storage
tracks.subscribe( function( list ) {
  storage('local').setItem( 'player-playlist', list );
});


// // get all tracks
// export const all = function() {
//   return get(tracks) || [];
// }

// clear track list
export const clear = function() {
  tracks.set([]);
}

// // set track list
// export const set = function( list ) {
//   tracks.set( list );
// }


// add a track to track list
export const add = function( track ) {
  // only if not already in
  var n = get(tracks).findIndex( t => ( track && track.src == t.src  ) );
  if( track && n < 0 ) {
    tracks.update( (list) => { list.push( track ); return list; } ); 
  }
}


// // remove a track from track list
// export const remove = function( track ) {
//   tracks.update( (list) => { return list.filter( function( t ) { return track && t.src != track.src } ); } );
// }


// // check if track is in track list
// export const has = function( track ) {
//   return all().find( function( t ) { return track && t.src == track.src } );
// }



// // on current track change
// current.subscribe( function( track ) {  

//   // search in playlist for current track
//   index.set( all().findIndex( t => ( track && track.src == t.src  ) ) );
// })


